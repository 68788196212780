<template>
  <RecordViewWrapper>
    <sdPageHeader title="Sales Offers" class="custom-header-full">
      <template #subTitle>
        <sdButton class="btn-add_new" size="default" type="primary">
          <router-link to="/sale/order">
            <sdFeatherIcons type="plus" size="14" /> Add new
          </router-link>
        </sdButton>
      </template>
    </sdPageHeader>
    <Main>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div v-if="isLoading" class="spin">
              <a-spin />
            </div>

            <div v-else>
              <TableWrapper class="table-data-view table-responsive">
                <a-table
                  :loading="isLoading"
                  :dataSource="dataSource"
                  :columns="columns"
                >
                  <template #status="{ record }">
                    <p v-if="record.sale.saleStatus === 1">Draft</p>
                    <p v-if="record.sale.saleStatus === 2">Confirmed</p>
                    <p v-if="record.sale.saleStatus === 3">Canceled</p>
                  </template>
                </a-table>
              </TableWrapper>
            </div>
          </sdCards>
        </a-col>
      </a-row>
    </Main>
  </RecordViewWrapper>
</template>
<script>
import { RecordViewWrapper } from "../../../view/crud/axios/style";
import { Main, TableWrapper } from "../../styled";
import { useStore } from "vuex";
import { computed, onMounted, ref, reactive } from "vue";
import moment from "moment";

const columns = [
  {
    title: "Partner",
    dataIndex: "sale.partenaireName",
    key: "sale.partenaireName",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Number",
    dataIndex: "sale.number",
    key: "sale.number",
  },
  {
    title: "Status",
    key: "status",
    slots: {
      customRender: "status",
    },
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    width: "90px",
  },
];

const ViewPage = {
  name: "ViewPage",
  components: { RecordViewWrapper, Main, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const OrderApi = computed(() => state.orderApi.data);
    const isLoading = computed(() => state.orderApi.loading);
    const selectedRowKeys = ref([]);

    onMounted(() => {
      dispatch("emptyProductOrderData");

      dispatch("orderGetAllData");
    });

    const handleDelete = (id) => {
      const confirm = window.confirm("Are you sure delete this?");
      if (confirm) {
        dispatch("orderApiDataDelete", id);
      }
      return false;
    };

    const formState = reactive({
      searchItem: "",
    });

    const onHandleSearch = () => {
      dispatch("contractDataSearch", formState.searchItem);
    };

    const dataSource = computed(() =>
      OrderApi.value && OrderApi.value.length
        ? OrderApi.value.map((sale, key) => {
            return {
              key: key + 1,
              sale,
              date: <div class="">{moment(sale.date).format("DD/MM/YYYY")}</div>,
              action: (
                <div class="table-actions">
                  <router-link class="edit" to={"/sale/edit-order/" + sale.id}>
                    <sdFeatherIcons type="edit" size={14} />
                  </router-link>
                  &nbsp;&nbsp;&nbsp;
                  <a-button class="delete" onClick={() => handleDelete(sale)}>
                    <sdFeatherIcons type="trash-2" size={14} />
                  </a-button>
                </div>
              ),
            };
          })
        : []
    );

    return {
      OrderApi,
      isLoading,
      selectedRowKeys,
      onHandleSearch,
      handleDelete,
      dataSource,
      columns,
      formState,
    };
  },
};

export default ViewPage;
</script>
